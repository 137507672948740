<template>
  <div class="vg_wrapper">
    <el-card v-loading="loadFlag">
      <div class="vd_button_group vg_mtb_16 flexV">
        <el-radio-group v-model="currentTabName" size="small" @input="switchTabs">
          <el-radio-button :label="0">报关</el-radio-button>
          <el-radio-button :label="1">内贸</el-radio-button>
          <el-radio-button :label="2">托收</el-radio-button>
          <el-radio-button :label="3">我司结算-辅料</el-radio-button>
        </el-radio-group>
        <el-button class="vg_ml_8" icon="el-icon-refresh-right" size="small" type="info" @click="buttonRefresh()">刷新</el-button>
        <el-link
          v-if="currentTabName === 3"
          class="vg_cursor"
          size="small"
          style="font-size: 18px; margin-left: auto"
          type="primary"
          @click="jump('invoiceDetailsForAuxiliaryMaterials')"
          >辅料开票明细
        </el-link>
      </div>
      <DynamicUTable
        v-if="switchTabStatus"
        ref="multiTable"
        :need-check-box="false"
        :columns="tableProperties"
        :totalPage="totalPage"
        :need-search="true"
        :table-data="tableData"
        @row-dblclick="dbClickJp"
        :need-fixed-height="true"
        @getTableData="getRequisitionsList"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import { requisitionAPI } from '@api/modules/requisition';
import { tableProperties, tableProperties2 } from '@/views/FinanceManagement/RequisitionManage/requisition';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'RequisitionList',
  components: {
    DynamicUTable
  },
  data() {
    return {
      tableProperties: tableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      currentTabName: 0,
      switchTabStatus: true
    };
  },
  mounted() {
    this.getRequisitionsList();
  },
  methods: {
    initData() {
      this.getRequisitionsList();
    },
    async getRequisitionsList() {
      this.loadFlag = false;
      let searchForm = this.$refs.multiTable.searchForm;
      searchForm.requisition_type = this.currentTabName;
      let find = this.tableProperties.find(x => x.prop === 'supp_abbr');
      if (find) find.visible = Number(searchForm.requisition_type) === 3;
      await requisitionAPI.getRequisitions(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.getRequisitionsList();
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/requisition_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: this.$route.query.perm_id,
            form_id: row.requisition_id
          })
        )
      });
    },
    switchTabs() {
      this.loadFlag = true;
      this.switchTabStatus = false;
      if (this.currentTabName === 0) this.tableProperties = tableProperties;
      else if (this.currentTabName === 1) this.tableProperties = tableProperties2;
      else {
        this.tableProperties = tableProperties;
        if (this.currentTabName === 3) this.tableProperties[0].visible = false;
      }
      this.getRequisitionsList();
      setTimeout(() => {
        this.switchTabStatus = true;
        setTimeout(() => {
          this.loadFlag = false;
        });
      }, 800);
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
